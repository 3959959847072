import { withCommas } from "./string";

export const stripNonNumericCharacters = (str: string) => str.replace(/\D/g, "");

export const isDigit = (char: string) => {
    switch (char) {
        case "9":
        case "8":
        case "7":
        case "6":
        case "5":
        case "4":
        case "3":
        case "2":
        case "1":
        case "0":
            return true;
        default:
            return false;
    }
};

export const limitToNumberOfDecimals = (val: number | undefined, numberOfDecimals: number) => {
    if (val === undefined) {
        return val;
    }

    const floorPower = 10 ** numberOfDecimals;
    return Math.floor(val * floorPower) / floorPower;
};

export const smartDecimalDisplay = (num: number, maxNumberOfDecimals = 2) => {
    const maxPowered = Math.round(num * 10 ** maxNumberOfDecimals);

    let currentPowered = maxPowered;
    let numberOfDecimals = maxNumberOfDecimals;
    while (numberOfDecimals > 0) {
        const result = currentPowered % 10;
        if (result !== 0) {
            return num.toFixed(numberOfDecimals);
        }

        currentPowered /= 10;
        numberOfDecimals -= 1;
    }

    return num.toFixed(0);
};

export const numberWithCommas = (num: number) => withCommas(num.toString());

export const percentString = (decimal: number) => {
    const numberOfDecimals = decimal < 0.001 ? 2 : 1;
    return `${(decimal * 100).toFixed(numberOfDecimals)}%`;
};